<template>
  <div>
      <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Durum</th>
                    <th>Başlangıç</th>                    
                    <th>Bitiş Tarihi</th>
                    <th>Görüşme Süresi</th>
                   
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in rows" :key="i">
                        <td>
                           <span :class="'badge badge-light-'+status_classes[item.status]">{{statusses[item.status]}}</span>
                          </td>
                        <td>{{moment(item.startDate).format('DD MMMM YYYY HH:mm')}}</td>
                        <td>{{moment(item.endDate).format('DD MMMM YYYY HH:mm')}}</td>
                        <td>{{duration(item.duration)}}</td>
                    </tr>
                    </tbody>
      </table>

      
  </div>
</template>

<script>
import moment from 'moment'
import Swal from 'sweetalert2'
import api from '@/core/services/ApiService'

export default {
    name:'TicketNotesList',
    props:['rows'],
    data(){
        return {
            note:null,
            status_classes:{
                'CO': 'info',
                'DC': 'danger',
                'CL': 'danger',
                'DA': 'success'

            },
            statusses: {
                'CO': 'Bağlandı',
                'DC': 'Bağlantı Koptu',
                'CL': 'Bağlantı Kapatıldı',
                'DA': 'Tamamlandı'
            }
        }
    },
    methods:{
        moment(date){
            return moment(date);
        },

        duration(time){
          let p = time.split(":");
          let hours = parseInt(p[0]);
          let minutes = parseInt(p[1]);
          let seconds = parseInt(p[2]);

          return (hours > 0 ? hours + " sa. " : '') + (minutes > 0 ? minutes + " dk. " :'')  + (seconds > 0 ? seconds + " sn." :'');
      },


        saveChanges(){

            let formData = {
                doctorId:this.note.consultantId,
                noteId:this.note.noteId,
                ticket:this.note.ticketId,
                note:this.note.note
            }
            
            console.log(formData);
            api.post('/User/UpdateUserNote',formData).then((res)=>{
                
                document.getElementById('close-btn').click();
            })
        },

        editNote(item){
            this.note = item;
        },

        deleteNote(item,i){
            Swal.fire({
                title:'Emin misiniz?',
                icon:'warning',
                text:'Danışman tarafından yazılan notu silmek istediğinize emin misiniz?',
                showCancelButton:true,
            }).then((res)=>{
                if(res.isConfirmed){
                   api.get('User/DeleteUserNote/'+item.noteId).then((res)=>{
                       document.location.reload();
                   })                  
                }
            })

        }
    




    }
}
</script>

<style>

</style>