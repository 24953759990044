<template>
<div>
    <div class="dropdown">
    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="CallListActions" ref="CallListActions" data-bs-toggle="dropdown" aria-expanded="false">
        İşlemler
    </button>
    <ul class="dropdown-menu" aria-labelledby="CallListActions">
        <li><a @click.prevent="notes(item)" class="dropdown-item">Notlar</a></li>
        <li>
            <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" href="" @click.prevent="detail(item)">Görüşme Detayları</a></li>
        
        </ul>
    </div>

     
</div>
</template>

<script>

import api from '@/core/services/ApiService'
export default {
    name:'CallListActions',
    props:['item','packagekey'],
    data(){
        return{
            callRows:null,
            
        }
    },
   
    methods:{
        notes(item){
            this.$emit('edit',item);
        },
        detail(item){
            
            
            api.get('/VCalls/GetTicketDetail/'+this.item.ticket+'?drawerType=details').then((res)=>{
                //this.callRows = res.data;
                this.$emit("detail",res.data);
                
               // document.getElementById("action-btn-"+this.item.ticket).click();
                //console.log(document.getElementById("action-btn-"+this.item.ticket));
                
            })
        }
    },
    
    
   
}
</script>