<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Görüşmeler</h3>

        
      </div>      
    </div>

    
    
    
    <div id="kt_account_profile_details" class="collapse show">

         <div id="package-detail" v-if="showNotes">
            <div class="card-body border-top p-9">
                
                <TicketNotesList :notes="ticketNotes"  />
            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelTicketNotes()" class="btn btn-white btn-active-light-primary me-2">Görüşme listesine geri dön</button>
            </div>
            <!--end::Actions-->
        </div>

       

        <div class="card-body border-top p-9" v-if="!showNotes">
          <form action="">

            <div class="d-flex border-bottom pb-9 mb-9 align-items-end">
                <div class="me-4">
                    Görüşme Türü
                    <select @change="changeTicketType()" class="form-select" name="" v-model="ticketType" id="">
                        <option :value="i" v-for="(item,i) in ticketTypes" :key="i">{{item}}</option>
                    </select>
                </div>

                <div class="me-4" v-if="ticketType==10 && consultantTypes">
                    Danışman Tipi
                    <select class="form-select" name="" v-model="consultantType" id="">
                        <option value="0">Tümü</option>
                        <option :value="item.consultantTypeId" v-for="(item,i) in consultantTypes" :key="i">{{item.consultantType}}</option>
                    </select>
                </div>

                <div class="me-4">
                    Durum
                    <select class="form-select" name="" v-model="ticketStatus" id="">
                        <option :value="i" v-for="(item,i) in ticketStatusses" :key="i">{{item.filtername}}</option>
                    </select>
                </div>

                <div class="me-4">
                    Tarih Aralığı
                    <div class="d-flex">
                        <input class="form-control me-2" v-model="dateRange.start" type="date">
                        <input class="form-control" v-model="dateRange.end" type="date">
                    </div>
                </div>

                <div class="me-4">
                    Arama
                    <div class="d-flex">
                        <input type="text" class="form-control me-2" v-model="searchWord">
                    </div>
                </div>



                

                <div class="me-4">
                    <input @click.prevent="filter()" type="submit" class="btn btn-info" value="Filtere">
                </div>
            </div>

            </form>

            <div class="table-responsive" v-if="calls">
              
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Danışan</th>
                    <th>Danışman</th>                    
                    <th>Görüşme Türü</th>
                    <th>Oluşturma Tarihi</th>
                    <th>Randevu Tarihi</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in calls" :key="i">
                    <td>
                        <div class="d-flex align-items-center">
                    
                    <div class="d-flex justify-content-start flex-column">
                        <a target="_blank" :href="'client/'+item.patient.userId+'/calls'" class="text-dark fw-bolder text-hover-primary fs-6">{{ item.patient.name }}  {{ item.patient.surname }}
                            <span :class="'badge badge-light-'+statusses.class[item.patient.status]">{{statusses.label[item.patient.status]}}</span>
                        </a>
                      <span class="text-muted fw-bold text-muted d-block fs-7">
                       <div class="d-flex flex-column">
                        <div>{{item.patient.email}}</div>
                        <div>{{item.patient.fullPhone}}</div>
                        
                       </div>
                       
                        </span>
                    </div>
                    
                    
                    
                    </div>
                    </td>
                    
                    <td>
                        <div class="d-flex align-items-center">
                            <div class="d-flex justify-content-start flex-column">
                                <a :href="'consultant/'+item.consultant.userId" target="_blank" class="text-dark fw-bolder text-hover-primary fs-6"> {{item.consultant.name+ ' '+item.consultant.surname}}
                                    <span :class="'badge badge-light-'+statusses.class[item.consultant.status]">{{statusses.label[item.consultant.status]}}</span>
                                </a>
                                <span class="text-muted fw-bold text-muted d-block fs-7">
                                    <div class="d-flex align-items-center">
                                        <span>{{item.consultant.consultantType}}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        {{ticketTypes[item.ticketTypeId]}}
                    </td>

                    <td>
                        <div class="d-flex flex-column">
                            <span class="text-dark fw-bolder">
                                {{moment(item.createdDate).format('DD MMMM YYYY HH:mm')}}
                            </span>
                            <span class="text-muted fw-bold text-muted d-block fs-7">
                                ({{duration(item.callTime)}})
                            </span>
                         </div>
                    </td>

                    <td>
                      <div v-if="item.ticketTypeId==10">{{moment(item.appointmentDate).format('DD MMMM YYYY HH:mm')}}</div>
                    </td>

                    <td>
                        <span v-if="!ticketStatusses[item.status]">{{item.status}}</span>
                        <span v-if="ticketStatusses[item.status]" :class="'badge badge-light-'+ticketStatusses[item.status].class">{{ticketStatusses[item.status].title}}</span>
                    </td>

                    <td><ActionsMenu :item="item" :packagekey="i" @edit="notes($event)" @detail="detail($event)" /></td>
                    
                    </tr>            
                </tbody>
              </table>

               <paging 
                v-if="recordcount>0" 
                :page="page" 
                :pagelength="pageLength" 
                :recordcount="recordcount" 
                @next="next" 
                @prev="prev"
                @gotoPage="gotoPage($event)"
                />
      
            </div>
        </div>
    </div>
    
         <button style="display:none" data-bs-toggle="modal" data-bs-target="#kt_modal_2" id="action-btn"></button>
    <div class="modal fade" ref="modal" tabindex="-1" id="kt_modal_2">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Görüşme Detayları</h5>

        <!--begin::Close-->
        <div
          class="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="svg-icon svg-icon-2x"></span>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body" v-if="callRows" >
         <TicketDetailList :rows="callRows" />
      </div>

      <div class="modal-footer">
        <button
            id="close-btn"
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
        >
          Kapat
        </button>
      </div>
    </div>
  </div>
</div>

    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from 'moment'
import Paging from '@/components/BD/Paging.vue'
import ActionsMenu from '@/components/dropdown/CallListActions.vue'
import TicketNotesList from '@/components/modals/forms/TicketNotesList.vue'
import TicketDetailList from '@/components/modals/forms/TicketDetailList.vue'

export default  {
  name:'Calls',
  components:{
      ActionsMenu,
      TicketNotesList,
      TicketDetailList,
      Paging
  },

  data(){
    return {
        dateRange:{
            start:null,
            end:null
        },
        
        ticketStatusses:{
            'JJ': {title:'Tümü','filtername':'Tümü','class':'warning'},
            'PE': {title:'Bekliyor','filtername':'Bekleyen','class':'warning'},
            'AC': {title:'Aktif','filtername':'Aktif','class':'info'},
            'CA': {title:'İptal Edildi','filtername':'İptal','class':'danger'},
            'DA': {title:'Tamamlandı','filtername':'Tamamlandı','class':'success'},
            'FA': {title:'Hata','filtername':'Hata','class':'danger'},
          
        },
        consultantType:0,
        consultantTypes:null,
        showNotes:false,
        ticketNotes:[],
        callRows:null,
        ticketType:0,
        ticketStatus:'JJ',
        ticketTypes:{
            0:'Tümü',
            1: 'Hemen Görüşme',
            10: 'Randevulu Görüşme'
        },
        recordcount:0,
        statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'

        }
      },
        calls:[],
        page:1,
        pageLength:10,

        searchWord:''
    }
  },

  


watch:{
      page:function(){
        this.getList();
      }
    },

  methods:{
      cancelTicketNotes(){
          this.showNotes = false;
      },

      detail(data){
         this.callRows = data;
         document.getElementById("action-btn").click();
      },
      
      notes(item){
          
          api.get("/User/ListUserNotes/Vcall/"+item.ticket+"?drawerType=notes").then((res)=>{
              if(res.data.length==0){
                  Swal.fire({
                      title:'Kayıt Yok',
                      text:'Bu görüşme için herhangi bir not girilmemiş',
                      icon:'error'

                  })
              }else{
                  this.showNotes=true;
                  this.ticketNotes = res.data;
              }
            
          })
          
      },

      changeTicketType(){
          if(this.ticketType==1){
              this.consultantType = 0;
          }

      },
      filter(){
          this.getList();
      },
      duration(time){
          let p = time.split(":");
          let hours = parseInt(p[0]);
          let minutes = parseInt(p[1]);
          let seconds = parseInt(p[2]);

           return (hours > 0 ? hours + " sa. " : '') + (minutes > 0 ? minutes + " dk. " :'')  + (seconds > 0 ? seconds + " sn." :'');
      },

      moment:function(date){
          return moment(date)
      },

      next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      },

      getList(){
          let searchWord = this.searchWord=='' ? '-' : this.searchWord;
          api.get("VCalls/ListTickets/"+this.ticketType+"/"+this.ticketStatus+"/"+this.consultantType+"/"+this.dateRange.start+"/"+this.dateRange.end+"/"+this.pageLength+"/"+this.page+"/"+searchWord).then((res)=>{
              this.recordcount = res.data.rowCount;
              this.calls = res.data.tickets;
              
          });
           
      },

      
  },


  mounted(){
      let storedDateRange = window.localStorage.getItem("YTMReportDateRange");
      if(!storedDateRange){
       this.dateRange = {
           start:moment().subtract(1,'months').format('YYYY-MM-DD'),
           end:moment().format('YYYY-MM-DD'),
       }     
      }

      //http://90.159.30.197:9003/          VCalls/ListTickets/0/JJ/0/2022-07-05/2022-08-11/10/1/-
      //https://boapi.ytmdanismanlik.com/   VCalls/ListTickets/0/JJ/0/2022-07-05/2022-08-11/10/1/-/

     

     setCurrentPageBreadcrumbs("Görüşme Listesi", ["Ayarlar","Görüşmeler"]);
    this.getList();
    api.get('User/ListDoctorType/AC').then((res)=>{
        this.consultantTypes = res.data;
    })

   
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>

